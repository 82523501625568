import React, { useEffect, useState, useRef, useContext } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faL } from '@fortawesome/free-solid-svg-icons';
import Skeleton from "react-loading-skeleton";
import DataContext from "../Elements/eventContext";

function MenuModal() {
  const didMountRef = useRef(true);
  const [setSession, SetSession] = useState("");
  const navigate = useNavigate();
  const [rowUserData, setRowUserData] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);

  const contextValues = useContext(DataContext)
  useEffect(() => {
    if (didMountRef.current) {
      SetSession(localStorage.getItem("USER_TOKEN"));
      getuserData();
      menuelist()
    }
    didMountRef.current = false;
  }, []);

  const menuelist = () => {
    ApiService.fetchData("menue-list").then((res) => {
      if (res.status === "success") {
        setMenuData(res.data);
        console.log("res.data", res.data);
        setLoading(false)
      }
    });
  };

  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        setRowUserData(res.rowUserData);
      }
    });
  };

  const goToPage = (route) => {
    navigate(route);
  };
  const clearSession = () => {
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
  } 
  
  const [showHelpSubMenu, setShowHelpSubMenu] = useState(-1);
  const [showHelpFirstSubMenu, setShowHelpFirstSubMenu] = useState(-1);
 
  const toggleHelpSubMenu = (index) => {
    setShowHelpSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const toggleHelpFirstSubMenu = (index) => {
    setShowHelpFirstSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  return (
    <>
      {setSession ? (
        <div
          className="msprofile d-flex align-items-center"
          onClick={(e) => goToPage("/account/account-overview")}
        >
          <div className="msprofile-media">
            <img src="/img/user.png" />
          </div>
          <div className="msprofile-content">
            <h6 className="mb-0 tx-14">Hi! {rowUserData.user_fname}</h6>
            <p className="tx-13 tx-color-02 mb-0">{rowUserData.user_email}</p>
          </div>
          <div className="msprofile-arrow">
            <i className="d-icon-angle-right"></i>
          </div>
        </div>
      ) : (
        <div
          className="msprofile d-flex align-items-center"
          onClick={loginModal}
        >
          <div className="msprofile-media">
            <img src="/img/user.png" />
          </div>
          <div className="msprofile-content">
            <h6 className="mb-0 tx-14">Hi Guest!</h6>
            <p className="tx-13 tx-color-02 mb-0">Login / Register</p>
          </div>
          <div className="msprofile-arrow">
            <i className="d-icon-angle-right"></i>
          </div>
        </div>
      )}
      <div className="sidemenu">
        <ul>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <li key={index} className="skeleton-menu-item">
                <div className="skeleton-menu-item-inner">
                  <Skeleton variant="text" width={100} />
                </div>
              </li>
            ))
          ) : (
            menuData.map((parent, index) => {
              const hasChildren = parent.children && parent.children.length > 0;
              const isSubMenuOpen = showHelpSubMenu === index;
              return (
                <li className={hasChildren ? 'sub-menu' : ''} key={parent.menu_slug}>
                  <div className='sub-menu-inner' onClick={clearSession}  >
                    {parent.menu_categoryid > 0 && parent.categories && parent.categories.cat_slug ?
                      <a href={"/collection/category/" + parent.categories.cat_slug} >{parent.menu_name}</a>
                      :
                      parent.menu_collectionid > 0 && parent.collection && parent.collection.cat_slug ? <a href={"/collection/" + parent.collection.cat_slug} >{parent.menu_name}</a>
                        :
                        parent.menu_pageid > 0  && parent.pages && parent.pages.page_url? <a href={"/" + parent.pages.page_url} >{parent.menu_name}</a>
                          :
                          <a href={parent.menu_customlink} >{parent.menu_name}</a>
                    }
                    {hasChildren && (
                      <div className='right' onClick={() => toggleHelpSubMenu(index)}>
                        <FontAwesomeIcon icon={isSubMenuOpen ? faCaretUp : faCaretDown} />
                      </div>
                    )}
                  </div>
                  {hasChildren && isSubMenuOpen && (
                    <ul>
                      {parent.children.map((firstChild, indexFirstChild) => {
                        const hasFirstChildren = firstChild.children && firstChild.children.length > 0;
                        const isFirstSubMenuOpen = showHelpFirstSubMenu === indexFirstChild;
                        if (firstChild.menu_show_image !== 1) {
                          return (
                            <li className={hasFirstChildren ? 'sub-menu' : ''} key={firstChild.menu_slug}>
                              <div className='sub-menu-inner' onClick={clearSession}  >
                                {firstChild.menu_categoryid > 0 && firstChild.categories && firstChild.categories.cat_slug? 
                                  <a href={"/collection/category/" + firstChild.categories.cat_slug} >
                                    {firstChild.menu_name}
                                  </a>
                                  :
                                  firstChild.menu_collectionid > 0  && firstChild.collection && firstChild.collection.cat_slug? <a href={"/collection/" + firstChild.collection.cat_slug} >{firstChild.menu_name}</a>
                                    : firstChild.menu_pageid > 0 && firstChild.pages && firstChild.pages.page_url ? <a href={"/" + firstChild.pages.page_url} >
                                      {firstChild.menu_name}
                                    </a> : <a href={firstChild.menu_customlink} >
                                      {firstChild.menu_name}
                                    </a>}
                                {hasFirstChildren && (
                                  <div className='right' onClick={() => toggleHelpFirstSubMenu(indexFirstChild)}>
                                    <FontAwesomeIcon icon={isFirstSubMenuOpen ? faCaretUp : faCaretDown} />
                                  </div>
                                )}
                              </div>
                              {hasFirstChildren && isFirstSubMenuOpen && (
                                <ul>
                                  {firstChild.children.map((thirdChild, indexThirdChild) => (
                                    <li key={thirdChild.cat_slug}>
                                      <div className='sub-menu-inner' onClick={clearSession}  >
                                        {thirdChild.menu_categoryid > 0 && thirdChild.categories && thirdChild.categories.cat_slug ?
                                          <a href={"/collection/category/"+thirdChild.categories.cat_slug} >
                                            {thirdChild.menu_name}
                                          </a>
                                          :
                                          thirdChild.menu_collectionid > 0 && thirdChild.collection && thirdChild.collection.cat_slug ? <a href={"/collection/" + thirdChild.collection.cat_slug} >{thirdChild.menu_name}</a>
                                            : thirdChild.menu_pageid > 0 && thirdChild.pages && thirdChild.pages.page_url ? <a href={"/" + thirdChild.pages.page_url} >
                                              {thirdChild.menu_name}
                                            </a> : <a href={thirdChild.menu_customlink} >
                                              {thirdChild.menu_name}
                                            </a>}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </li>
              );
            })
          )}
        </ul>
      </div>
      <hr></hr>
      <div className="mslist">
        <ul>
          {setSession ? (
            <>
              <li onClick={(e) => goToPage("/account/orders")}>My Orders</li>
              <li onClick={(e) => goToPage("/account/address")}>Addresses</li>
              <li onClick={(e) => goToPage("/account/wishlist")}>
                Collection & Wishlist
              </li>
            </>
          ) : (
            <>
              <li onClick={loginModal}>My Orders</li>
              <li onClick={loginModal}>Addresses</li>
              <li onClick={loginModal}>Collection & Wishlist</li>
            </>
          )}

          <li onClick={(e) => goToPage("/account/help-and-support")}>
            Help & Support
          </li>
        </ul>
      </div>
    </>
  );
}
export default MenuModal;
