import React, { useEffect, useRef, useState, useCallback } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { BrowserView, MobileView } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
// SwiperCore.use([Navigation, Pagination]);

function HomeTopBanner() {
  const didMountRef = useRef(true);
  const [resTopBannerData, setResTopBannerData] = useState([]);
  const [resMobileBannerData, setResMobileBannerData] = useState([]);
  const [loading, setLoading] = useState()
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    // if (didMountRef.current) {
    getBannerData();
    // }
    // didMountRef.current = false;
  }, []);
  const getBannerData = () => {
    setLoading(true)
    ApiService.fetchData("top-banner-list").then((res) => {
      if (res.status == "success") {
        setResTopBannerData(res.resTopBannerData);
        setResMobileBannerData(res.resMobileBannerData);
        setImageUrl(res.slider_image_path);
        setLoading(false)
      }
    });
  };

  const resetFilter = (redirectUrl) => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    // window.location.reload()
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 100);
  }

  const renderCarouselItem = (value, index) => {
    if (value.slider_view === 2 && value.slider_video !== "") {
      return renderVideoItem(value, index);
    } else if (value.slider_type === 1 && value.slider_url !== "") {
      return renderLinkItem(value, index);
    } else if (value.slider_type === 2 && value.cat_id) {
      return renderCategoryItem(value, index);
    } else if (value.slider_type === 3 && value.tag_id) {
      return renderTagItem(value, index);
    } else {
      return renderDefaultItem(value, index);
    }
    //return renderDefaultItem(value, index);
  };

  const renderVideoItem = (value, index) => {
    return (
      <div className="item" key={index}>
        <video
          src={value.slider_video ? imageUrl + value.slider_video : "/assets/img/v01.mp4"}
          autoPlay="autoplay"
          loop
          muted
          playsInline
        ></video>
      </div>
    );
  };

  const renderLinkItem = (value, index) => {
    return (
      <div className="item" key={index}>
        <a href="javascript:void(0)" onClick={(e)=>resetFilter(value.slider_url)}>
          <img
            src={value.slider_image ? imageUrl + value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderCategoryItem = (value, index) => {
    return (
      <div className="item" key={index}>
        <a href="javascript:void(0)" onClick={(e)=>resetFilter("/collection/category/" + value.cat_slug)}>
          <img
            src={value.slider_image ? imageUrl + value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderTagItem = (value, index) => {
    return (
      <div className="item" key={index}>
        <a href="javascript:void(0)" onClick={(e)=>resetFilter("/collection/tag/" + value.tag_slug)}>
          <img
            src={value.slider_image ? imageUrl + value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderDefaultItem = (value, index) => {
    return (
      <div className="item" key={index} onClick={(e)=>resetFilter()}>
        <img
          src={value.slider_image ? imageUrl + value.slider_image : constant.DEFAULT_IMAGE}
          alt={value.slider_name}
        />
      </div>
    );
  };

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
  }, [])

  
  const sliderRefmob = useRef(null);
  const handlePrevmob = useCallback(() => {
      if (!sliderRefmob.current) return;
      sliderRefmob.current.swiper.slidePrev();
  }, []);

  const handleNextmob = useCallback(() => {
      if (!sliderRefmob.current) return;
      sliderRefmob.current.swiper.slideNext();
  }, [])

  return (
    <>  <BrowserView>

      {loading == true ?
        <div className="home-slider">
          <Skeleton
            variant="text"
            width="100%"
            height={600}
          />
        </div>
        :
        <div className="home-slider">{/* {...sliderOptions} */}
          <Swiper
            slidesPerView={1}
            navigation={false}
            loop={true}
            pagination={false}
            ref={sliderRef}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 1,
              },
            }}
          >
            {resTopBannerData
              ? resTopBannerData.map((value, index) => (<>
              
               <SwiperSlide key={index}>{renderCarouselItem(value, index)}</SwiperSlide>
              
              </>
               
              ))
              : null}
          </Swiper>
          <div className='swiper-arrow'>
          <div className="prev-arrow" onClick={handlePrev}><img src="/img/leftarrow.svg" className="wd-28"></img></div>
           <div className="next-arrow" onClick={handleNext}><img src="/img/rightarrow.svg" className="wd-28"></img></div>
          </div>
        </div>}
    </BrowserView>
      <MobileView>
        {loading == true ?
          <div className="mhome-slider">
            <Skeleton
              variant="text"
              height={350}
            />
          </div>

          : <div className="mhome-slider">{/* {...sliderOptions} */}
            <Swiper
              slidesPerView={1}
              navigation={false}
              loop={true}
              pagination={false}
              ref={sliderRefmob}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 1,
                },
              }}
            >
              {resMobileBannerData
                ? resMobileBannerData.map((value, index) => (
                  <SwiperSlide key={index}>{renderCarouselItem(value, index)}</SwiperSlide>
                ))
                : null}
            </Swiper>
            <div className='swiper-arrow'>
          <div className="prev-arrow" onClick={handlePrevmob}><img src="/img/leftarrow.svg" className="wd-28"></img></div>
           <div className="next-arrow" onClick={handleNextmob}><img src="/img/rightarrow.svg" className="wd-28"></img></div>
          </div>
          </div>}
      </MobileView>

    </>
  );
}
export default HomeTopBanner;
