// const API_URL =   "https://ashnavaswani.studykee.com/csadmin/api/"
const API_URL =   "https://ashnavaswani.com/csadmin/api/"
const BASE_URL =   "https://ashnavaswani.com/"  
const FRONT_URL =   "https://ashnavaswani.com/" 
const LOGO_IMAGE =   "https://ashnavaswani.com/img/logo.png" 

const DEFAULT_IMAGE = "/img/defaultimage.jpg";
const CATEGORIES = [];
const themesetting = {}; 
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE,LOGO_IMAGE };
