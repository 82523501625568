import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductBox from "./product_box";
import { useNavigate } from "react-router-dom";
function TagWiseProducts({ dataObj }) {
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const [loading, setLoading] = useState(true);
  const [tagCategoriesData, setTagCategoriesData] = useState([]);
  useEffect(() => {
    if (didMountRef.current) {
      getTagCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getTagCategoryData = () => {
    const dataString = {
      data: dataObj,
    };
    ApiService.postData("tags-wise-products-new", dataString).then((res) => {
      if (res.status == "success") {
        setTagCategoriesData(res.tagsData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const productCarouselOptions = {
    slidesPerView: "auto",
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 2.2,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 5.4,
      },
    },
  };

  const resetFilter = (url) => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    navigate(url)
  }

  return (
    <>
      {loading == true ? (
        <>
          {Array.from({ length: 2 }).map((_, index) => (
            <section className="sec-gap-top" key={index}>
              <div className="container">
                <div className="row">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="col-lg-3 col-6">
                      <div className="product">
                        <figure className="product-media">
                          <Skeleton variant="rectangular" width={280} height={315} />
                        </figure>
                        <div className="product-details">
                          <h3 className="product-name">
                            <Skeleton variant="text" width={150} />
                          </h3>
                          <div className="product-price">
                            <Skeleton variant="text" width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </>
      ) : tagCategoriesData.length > 0 ? (
        <>
          {tagCategoriesData.map((value, index) => {
            if (value.tagProducts.length > 0) {
              if (dataObj.home_display_type === 2) {
                return (
                  <div key={index}>
                    <div className="section-title d-flex align-items-center justify-content-between mb-4">
                      <h3 className="mb-0">{value.tag_name}</h3>
                      <a href={"javascript:void(0)"}
                        onClick={() => resetFilter("/collection/tag/" + value.tag_slug)}
                      >
                        View All <i className="d-icon-arrow-right"></i>
                      </a>
                    </div>
                    <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        return (
                          <ProductBox productValue={subvalue} indexProduct={indexProduct} classType="product mb-5" key={indexProduct} />
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <div className="section-title d-flex align-items-center justify-content-between mb-4">
                      <h3 className="mb-0">{value.tag_name}</h3>
                      <a href={"javascript:void(0)"}
                        onClick={() => resetFilter("/collection/tag/" + value.tag_slug)}
                      >
                        View All <i className="d-icon-arrow-right"></i>
                      </a>
                    </div>
                    <Swiper 
                    loop="false"
                    slidesPerView={dataObj.home_column}
                    spaceBetween="10"
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }} >
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        return (
                          <SwiperSlide key={indexProduct}>
                            <ProductBox productValue={subvalue} indexProduct={indexProduct} classType="product" key={indexProduct} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                );
              }
            }
          })}
        </>
      ) : null}
    </>
  );
}
export default TagWiseProducts;
