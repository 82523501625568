import React, { useEffect, useState, useRef, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Helmet } from "react-helmet";
import CategoriesList from '../../Containers/Pages/components/categories_list'
import AwardList from '../../Containers/Pages/components/award_list'
import TeamList from '../../Containers/Pages/components/team_list'
import EventList from '../../Containers/Pages/components/event_list'
import BrijImageSlider from '../../Containers/Pages/components/brij_image_slider'
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import GridBannerThird from "../../Components/Elements/grid_banner_third";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import GridBannerSecond from "../../Components/Elements/grid_banner_second";
import FeaturedVideoProducts from "../../Components/Elements/featured_video_products";
import HomeInstagram from "../HomeInstagram";
import HomeCelebrities from "../../Components/Elements/home_celebrities";
import CelebritiesAv from "../Pages/components/celebrities_av";

function Home() {
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true);
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [pageData, setPageData] = useState("")
  const [categoryData, setCategoryData] = useState([]);
  const [categoryImagePath, setCategoryImagePath] = useState([]);
  const [browserSectionData, setBrowserSectionData] = useState([])
  const [mobileSectionData, setMobileSectionData] = useState([])
  const [mediaImageUrl, setMediaImageUrl] = useState("")
  useEffect(() => {
    if (didMountRef.current) {
      getDashboardData();
      getCategoryData();
      resetFilter();
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 1000);
      const getPageData = {
        slug: "home",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
          setPageData(res.data)

        } else {

        }
      });
    }
    didMountRef.current = false;
  }, []);
  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
  }, [])
  const sliderRefmob = useRef(null);
  const handlePrevmob = useCallback(() => {
      if (!sliderRefmob.current) return;
      sliderRefmob.current.swiper.slidePrev();
  }, []);

  const handleNextmob = useCallback(() => {
      if (!sliderRefmob.current) return;
      sliderRefmob.current.swiper.slideNext();
  }, [])

  const getCategoryData = () => {
    ApiService.fetchData("featured-category").then((res) => {
      if (res.status == "success") {
        setCategoryData(res.resCategory);
        setCategoryImagePath(res.category_image_path);
      }
    });
  };
  const handleCartCount = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
  };

  const resetFilter = () => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    // window.location.reload()
  }

  const getDashboardData = () => {
    ApiService.fetchData("dashboard").then((res) => {
      if (res.status == "success") {
        setMediaImageUrl(res.mediaImageUrl)
        setBrowserSectionData(res.resAppearanceHomeBrowserData)
        setMobileSectionData(res.resAppearanceHomeMobileData)
      }
    });
  };
  const convertStyleStringToObject = (styleString) => {
    if (!styleString) return {}; // Return an empty object if styleString is null or undefined
    return styleString.split(';').reduce((styleObject, styleProperty) => {
      if (styleProperty) {
        const [property, value] = styleProperty.split(':');
        if (property && value) {
          const camelCasedProperty = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
          styleObject[camelCasedProperty] = value.trim();
        }
      }
      return styleObject;
    }, {});
  };

  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Ashna Vaswani"} />
        {pageData.page_meta_keyword != null ? <meta name="keywords" content={pageData.page_meta_keyword} /> : ""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:image" content={constant.FRONT_URL + 'img/logo.png'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Ashna Vaswani"} />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Ashna Vaswani"} />
        <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />
      <BrowserView>
        {/* {spinnerLoading && <SpinnerLoader />} */}
        {browserSectionData.length > 0 && (
          browserSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
              inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
            }
            return (
              <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                  <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                      valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                        <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                          {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                              let dataObj;
                              if (valueRow.home_type === 1) { //Products
                                if (valueRow.home_layout_for_type === 1) {
                                  dataObj = {
                                    'id': valueRow.home_cat_id,
                                    'home_product_name': valueRow.home_product_name,
                                    'home_column': valueRow.home_column,
                                    'home_display_type': valueRow.home_display_type,
                                    'home_no_items': valueRow.home_no_items,
                                    'home_col_grid': 'cols-xl-',
                                  };
                                  return (
                                    <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                  );
                                } else {
                                  dataObj = {
                                    'id': valueRow.home_tag_id,
                                    'home_product_name': valueRow.home_product_name,
                                    'home_column': valueRow.home_column,
                                    'home_display_type': valueRow.home_display_type,
                                    'home_no_items': valueRow.home_no_items,
                                    'home_col_grid': 'cols-xl-',
                                  };
                                  return (
                                    <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                  );
                                }
                              } else if (valueRow.home_type === 2) { //Categories
                                dataObj = {
                                  'home_cat_title': valueRow.home_cat_title,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_cat_multi_id': valueRow.home_cat_multi_id,
                                  'home_cat_display_title': valueRow.home_cat_display_title,
                                };
                                return (
                                  <CategoriesList key={indexRow} dataObj={dataObj}/>
                                );
                              } else if (valueRow.home_type === 4) { //Text
                                return (
                                  <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                  </div>
                                );
                              } else if (valueRow.home_type === 5) { //Images
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                  <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <div className="overlay-zoom singleImgbox">
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                    </div>
                                  </a>
                                );
                              } else if (valueRow.home_type === 6) { //Image Gallery
                                if (valueRow.has_many_gallery.length > 0) {
                                  return (
                                    <div className="gallerySlider">
                                      <Swiper
                                      loop={true}
                                      slidesPerView={valueRow.home_column}
                                      spaceBetween={valueRow.home_space_between_slide}
                                      ref={sliderRef}
                                    >
                                      {valueRow.has_many_gallery.map((value, index) => (
                                        <SwiperSlide key={index}>
                                          <a href={value.home_gallery_image_link? value.home_gallery_image_link: "javascript:void(0)"}>
                                            <img src={mediaImageUrl + value.home_gallery_image_url} className="img-fluid" alt={'Ashna Vaswani'} />
                                          </a>
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                    <div className='swiper-arrow'>
          <div className="prev-arrow" onClick={handlePrev}><img src="/img/leftarrow.svg" className="wd-28"></img></div>
           <div className="next-arrow" onClick={handleNext}><img src="/img/rightarrow.svg" className="wd-28"></img></div>
          </div>
                                    
                                    
                                    </div>
                                  
                                  );
                                }
                              } else if (valueRow.home_type === 11) {  //Title
                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                  <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                      valueRow.home_brij_heading_size || 'h1',
                                      null,
                                      valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                  </div>
                                );
                              } else if (valueRow.home_type === 15) {  //Revolve Slider
                                return (
                                  <HomeTopBanner />
                                );
                              } else if (valueRow.home_type === 16) {  //Celebrities Av
                                dataObj = {
                                  'home_column': valueRow.home_celebrities_column,
                                  'home_display_type': valueRow.home_celebrities_display_type,
                                  'home_no_items': valueRow.home_celebrities_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <CelebritiesAv key={indexRow} dataObj={dataObj} />
                                );
                              } else if (valueRow.home_type === 17) {  //instagram
                                dataObj = {
                                  'home_column': valueRow.home_celebrities_column,
                                  'home_display_type': valueRow.home_celebrities_display_type,
                                  'home_no_items': valueRow.home_celebrities_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (
                                 
                                  <HomeInstagram key={indexRow} dataObj={dataObj} />
                                );
                              }
                            })}
                        </div></>
                      ))}
                  </div>
                </div>
              </section>
            );
          })
        )}
      </BrowserView>
      <MobileView>
        {/* {spinnerLoading && <SpinnerLoader />} */}
        {mobileSectionData.length > 0 && (
          mobileSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
              inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
            }
            return (
              <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                  <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                      valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                        <><div className={"col-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                          {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                              let dataObj;
                              if (valueRow.home_type === 1) { //Products
                                if (valueRow.home_layout_for_type === 1) {
                                  dataObj = {
                                    'id': valueRow.home_cat_id,
                                    'home_product_name': valueRow.home_product_name,
                                    'home_column': valueRow.home_column,
                                    'home_display_type': valueRow.home_display_type,
                                    'home_no_items': valueRow.home_no_items,
                                    'home_col_grid': 'cols-',
                                  };
                                  return (
                                    <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                  );
                                } else {
                                  dataObj = {
                                    'id': valueRow.home_tag_id,
                                    'home_product_name': valueRow.home_product_name,
                                    'home_column': valueRow.home_column,
                                    'home_display_type': valueRow.home_display_type,
                                    'home_no_items': valueRow.home_no_items,
                                    'home_col_grid': 'cols-',
                                  };
                                  return (
                                    <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                  );
                                }
                              } else if (valueRow.home_type === 2) { //Categories
                                dataObj = {
                                  'home_cat_title': valueRow.home_cat_title,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_cat_multi_id': valueRow.home_cat_multi_id,
                                  'home_cat_display_title': valueRow.home_cat_display_title,
                                };
                                return (
                                  <CategoriesList key={indexRow} dataObj={dataObj}
                                  />
                                );
                              } else if (valueRow.home_type === 4) { //Text
                                return (
                                  <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                  </div>
                                );
                              } else if (valueRow.home_type === 5) { //Images
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                  <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                  </a>
                                );
                              } else if (valueRow.home_type === 6) { //Image Gallery
                                if (valueRow.has_many_gallery.length > 0) {
                                  return (
                                    <div className="gallerySlider">
                              
                                     <Swiper
                                      loop={true}
                                      slidesPerView={valueRow.home_column}
                                      spaceBetween={valueRow.home_space_between_slide}
                                      ref={sliderRefmob}
                                    >
                                      {valueRow.has_many_gallery.map((value, index) => (
                                        <SwiperSlide key={index}>
                                          <a href={value.home_gallery_image_link? value.home_gallery_image_link: "javascript:void(0)"}>
                                            <img src={mediaImageUrl + value.home_gallery_image_url} className="img-fluid" alt={'Ashna Vaswani'} />
                                          </a>
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                    <div className='swiper-arrow'>
          <div className="prev-arrow" onClick={handlePrevmob}><img src="/img/leftarrow.svg" className="wd-28"></img></div>
           <div className="next-arrow" onClick={handleNextmob}><img src="/img/rightarrow.svg" className="wd-28"></img></div>
          </div>
                                    
                                  
                                   </div>
                                  );
                                }
                              } else if (valueRow.home_type === 11) {  //Title
                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                  <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                      valueRow.home_brij_heading_size || 'h1',
                                      null,
                                      valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                  </div>
                                );
                              } else if (valueRow.home_type === 15) {  //Revolve Slider
                                return (
                                  <HomeTopBanner />
                                );
                              } else if (valueRow.home_type === 16) {  //Celebrities Av
                                dataObj = {
                                  'home_column': valueRow.home_celebrities_column,
                                  'home_display_type': valueRow.home_celebrities_display_type,
                                  'home_no_items': valueRow.home_celebrities_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (
                                  <CelebritiesAv key={indexRow} dataObj={dataObj} />
                                );
                              } else if (valueRow.home_type === 17) {  //instagram
                                dataObj = {
                                  'home_column': valueRow.home_celebrities_column,
                                  'home_display_type': valueRow.home_celebrities_display_type,
                                  'home_no_items': valueRow.home_celebrities_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (
                                  <HomeInstagram key={indexRow} dataObj={dataObj} />
                                );
                              }
                            })}
                        </div></>
                      ))}
                  </div>
                </div>
              </section>
            );
          })
        )}
      </MobileView>
      <Footer />
    </>
  );
}
export default Home;
